 //funciones de React
import React from 'react';

//Importando MyModules
import '../MyModules/anchor-ease.js';

//Router
import { BrowserRouter as Router} from 'react-router-dom';

//Components
import PrincipalRoutes from '../Components/PrincipalRoutes.jsx';

//importando fontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab, faChevronDown, faChevronUp);

let App = props => (
	<Router>
		<PrincipalRoutes />
	</Router>
)

export default App;