import React, { Component } from 'react';

//Assets
import Logo from './img/logoP-lao.svg';

import SmoothScroll from 'smooth-scroll';

//Otras librerias
//import navicon from './js/nav-icon'
import './js/nav-icon';

//React Router
import {NavLink} from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

//css
import './css/Navbar.css'

class Navbar extends Component {

	toScroll(el) {

		var scroll = new SmoothScroll('a[href*="#"]' - 60, {speed: 1500, easing: 'easeInOutQuint', clip: true});

		scroll.animateScroll(el);

		if(window.innerWidth < 768){
			document.querySelector(".trigger").click()
		}


	}

	render(){

		return(
			<nav className="navbar fixed-top navbar-expand-md navbar-light bg-p-lao">

				<div id="anchor">
					<NavHashLink className="navbar-brand mx-auto" exact to="/#inicio" scroll={this.toScroll} activeClassName="active">
						<div style={{'height' : '50px', 'position' : 'relative'}}>
							<div id="container-logo-navbar">
								<img id="logo-navbar" src={Logo} alt="LogoP-lao"/>
							</div>
						</div>
					</NavHashLink>
				</div>
				
				
				<button className="navbar-toggler x collapsed" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
					<svg id="icono" width="40px" height="40px" viewBox="0 0 100 100">
						<path id="first" d="M 30 40 L 70 40 C 90 40 90 75 60 85 A 40 40 0 0 1 20 20 L 80 80"></path>
						<path id="middle" d="M 30 50 L 70 50"></path>
						<path id="second" d="M 70 60 L 30 60 C 10 60 10 20 40 15 A 40 38 0 1 1 20 80 L 80 20"></path>
					</svg>
					<div className="trigger"></div>
					{/*<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>*/}
					{/*<span className="navbar-toggler-icon"></span>*/}
				</button>
				 
				  <div className="navbar-collapse collapse" id="navbarNav">
					<ul className="navbar-nav nav-fill ml-auto col-md-9">
						  <li className="nav-item">
							<span className="nav-link"><NavHashLink exact to="/#inicio" activeClassName="active" scroll={this.toScroll} >Inicio</NavHashLink></span>
						  </li>
						<li className="nav-item">
							<span className="nav-link"><NavHashLink exact to="/#nosotros" activeClassName="active" scroll={this.toScroll}>Quienes Somos</NavHashLink></span>
						</li>
						<li className="nav-item">
							<span className="nav-link"><NavHashLink exact to='/#servicios' activeClassName="active" scroll={this.toScroll} >Servicios</NavHashLink></span>
						</li>
						{/*<li className="nav-item">
							<span className="nav-link"><NavLink to="/">Portafolio</NavLink></span>
						</li> activeStyle={{'borderBottom': 'solid 1px #E4B008'}} */}
						<li className="nav-item">
							<span className="nav-link"><NavHashLink exact to='/#contacto' activeClassName="active" scroll={this.toScroll} >Contacto</NavHashLink></span>
						</li>
					</ul>
				</div>
			</nav>
		)
	}
}

export default Navbar;