import React from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SmoothScroll from 'smooth-scroll';

import { NavHashLink } from 'react-router-hash-link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Slider from 'react-slick';

import './css/galeriaInicio.css';

let toScroll = (el) => {

  var scroll = new SmoothScroll('a[href*="#"]', {speed: 1500, easing: 'easeInOutQuint'});

  scroll.animateScroll(el);

}

let SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent" , right: '0px', zIndex: '50', color: '#FFFFFF'}}
      onClick={onClick}
    />
  );
}

let SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent", left: '0px', zIndex: '50', color: '#FFFFFF'}}
      onClick={onClick}
    />
  );
}

let settings = {
      autoplay: true,
      autoplaySpeed: 4000,
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    }

let GaleriaInicio = props => (
  <section>
    <div className="container-fluid d-flex justify-content-end galeria-inicio-container">
      <div className="col-md-11" style={{'padding': '0px'}}>
        <Slider {...settings} className="galeria-inicio">
          <div className="d-flex row galeria-inicio-element1">
              <div className="col-12 col-md-6 text-center my-auto">
                <h2>Somos una <span>Agencia</span> de </h2>
                <h1>Creatividad</h1>
                {/*<p className="text-justify">El diseño web es una actividad que consiste en la planificación, diseño, implementación y mantenimiento de aplicaciones web</p>*/}
              </div>
              <div className="col-12 col-sm-7 col-md-6 mx-auto my-auto">
                <img className="img-fluid" src="/img/inicio/galeriaInicio/Imagen1Inicio.png" height="100%" alt="img1"/>
              </div>
          </div>
          <div className="d-flex row galeria-inicio-element2">
              <div className="col-12 col-md-6 text-center my-auto">
                <h2>Diseño y  <span>desarrollo</span> de </h2>
                <h1>Web</h1>
                {/*<p className="text-justify">El diseño web es una actividad que consiste en la planificación, diseño, implementación y mantenimiento de aplicaciones web</p>*/}
              </div>
              <div className="col-12 col-sm-7 col-md-6 mx-auto my-auto">
                <img className="img-fluid" src="/img/inicio/galeriaInicio/Imagen2Inicio.png" height="100%" alt="img1"/>
              </div>
          </div>
        </Slider>
        <div id="container-curva-inicio" className="col-12">
          <div className="col-12">
            <img id="curva-inicio" className="mx-auto" src="/img/inicio/iconsInicioSvg/curvaazul.svg" alt="icono de curva" />
          </div>
          <div className="col-12 text-center">
            <button id="button-curva-inicio" ><NavHashLink className="navbar-brand mx-auto" exact to="/#nosotros" scroll={toScroll} activeClassName="active" ><FontAwesomeIcon icon={"chevron-down"} style={{borderBottom: 'none'}}/></NavHashLink></button>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default GaleriaInicio;
