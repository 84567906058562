import React from 'react';
import './css/inicio.css';

import Navbar from '../../Components/UniversalComponents/Navbar.jsx';
import Nosotros from './Components/Nosotros.jsx';
import GaleriaInicio from './Components/GaleriaInicio.jsx';
// import MenuHover from './Components/MenuHover.jsx';
import Servicios from './Components/Servicios.jsx';
import IdeasQueFluyen from './Components/IdeasQueFluyen.js';
import Contacto from './Components/Contacto.jsx';

const Inicio = (props) => (
	<div id="inicio-container">
		{/*Navbar*/}
		<Navbar location={props.location}/>
		<GaleriaInicio />
		<Nosotros />
		{/* <MenuHover /> */}
		<Servicios />
		<IdeasQueFluyen />
		<Contacto />
	</div>
)

export default Inicio;
