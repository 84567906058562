// Highlight the top nav as scrolling occurs El Mio
let Dimension = (element) => {
    var elmHeight, 
		elmMargin, 
		elm = element;
    if(document.all) {// IE
        elmHeight = parseInt(elm.currentStyle.height, 10);
        elmMargin = parseInt(elm.currentStyle.marginTop, 10) + parseInt(elm.currentStyle.marginBottom, 10);
    } else {// Mozilla
        elmHeight = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('height'), 10);
        elmMargin = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top'), 10) + parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom'), 10);
    }
    return (elmHeight+elmMargin);
}

( () => {
	
	window.addEventListener('scroll', function() {
		
		var WindowTop = this.scrollY;
		
		document.querySelectorAll('.section').forEach( (This) => {
		  	if(WindowTop > This.offsetTop - 50&& 
               WindowTop < This.offsetTop + Dimension(This)
            ){
				
                var idSection = This.getAttribute('id');
				
				document.querySelectorAll('.nav-link a').forEach( (This) => {

				  if(`/#${idSection}` === This.getAttribute('href')){
						This.classList.add('active')
					}else{
						This.classList.remove('active')
					}
				});
			}
		});
	})
	
})();