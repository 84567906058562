import React from 'react';

import Logo from './img/logoP-lao.svg';

import './css/Footer.css';

let Footer = props => (
    <footer id="Footer" className="container-fluid d-flex justify-content-center bg-white py-4">
        <img style={{ height: '100px' }} src={Logo} alt='p-lao' />
    </footer>
)

export default Footer