import React from 'react';
import ReactDOM from 'react-dom';
import jquery from 'jquery';

//importando bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import 'popper.js/dist/popper';

window.React = React;
window.ReactDOM = ReactDOM;
window.jQuery = jquery;
window.jquery = jquery;
window.$ = jquery;