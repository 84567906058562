import React from 'react';

function AppLayout(props){
	return(
		<div>
			{props.children}
		</div>
	)
}

export default AppLayout;