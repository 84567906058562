import React from 'react'

import Ideas from './assets/SVG/Ideas.svg'

const IdeasQueFluyen = () => {
    return (
        <div className='my-4 py-5 d-flex justify-content-center align-items-center text-white' style={{ backgroundColor: '#26B5B9' }} >

            <img className='img-fluid pl-4' style={{ maxHeight: '100px' }} src={Ideas} alt='p-lao' />
            <p className='m-0 text-center' style={{ fontSize: '2.5em' }} >"IDEAS QUE <b>FLUYEN</b>"</p>
            
        </div>
    )
}

export default IdeasQueFluyen