import React from 'react';

let Video = props => (
  <div id="videoContainer" className="container" style={{marginTop: '25px', marginBottom: '25px'}}>
    <div className="row">
      <div className="col-12">
        <video className="img-fluid" src="/video/p_laovideo.mp4" alt="Video P-lao" controls style={{borderRadius: '25px'}} />
      </div>
    </div>
  </div>
)

export default Video