// JavaScript Document

import Moveit from './moveit';
import DocReady from 'es6-docready';

DocReady( () => {
	
	let LogoContainerNavbar = document.querySelector('#container-logo-navbar');
	let LogoNavbar = document.querySelector('#logo-navbar');
	
	document.querySelector('.navbar-toggler').addEventListener('click', function() {
		if(document.querySelector('.navbar-collapse').classList.contains('show') === false){
			
			LogoContainerNavbar.style.position = 'relative';
			LogoContainerNavbar.style.height = 'auto';
			LogoContainerNavbar.style.width = 'auto';
			LogoContainerNavbar.style.boxShadow = 'none';
			LogoContainerNavbar.style.transition = 'none';
			
			LogoNavbar.style.transform = 'translateY(10px)';
			LogoNavbar.style.left = 'auto';
			
		} else if(document.querySelector('.navbar-collapse').classList.contains('show') === true){
			
			LogoContainerNavbar.style.position = 'relative';
			LogoContainerNavbar.style.height = '120px';
			LogoContainerNavbar.style.width = '120px';
			LogoContainerNavbar.style.boxShadow = '0px 5px 10px -3px rgba(0, 0, 0, 0.5)';
			LogoContainerNavbar.style.transition = 'all 0.8s';
			
			LogoNavbar.style.transform = 'translateY(39px)';
			LogoNavbar.style.left = '10px';
			
		}
	});
	
	window.addEventListener('resize', () => {
		
		if(document.querySelector('.navbar-collapse').classList.contains('show') === true){
			document.querySelector('.trigger').click();
		}
		
		if(window.innerWidth > 768){
			
			LogoContainerNavbar.style.position = 'relative';
			LogoContainerNavbar.style.height = 'auto';
			LogoContainerNavbar.style.width = 'auto';
			LogoContainerNavbar.style.boxShadow = 'none';
			LogoContainerNavbar.style.transition = 'none';
			
			LogoNavbar.style.transform = 'translateY(0px)';
			LogoNavbar.style.left = 'auto';
			
		} else if(window.innerWidth <= 768){
			
			LogoContainerNavbar.style.position = 'relative';
			LogoContainerNavbar.style.height = '120px';
			LogoContainerNavbar.style.width = '120px';
			LogoContainerNavbar.style.boxShadow = '0px 5px 10px -3px rgba(0, 0, 0, 0.5)';
			LogoContainerNavbar.style.transition = 'all 0.8s';
			
			LogoNavbar.style.transform = 'translateY(39px)';
			LogoNavbar.style.left = '10px';
			
		}
		
	});
	
});

//animacion del icono
DocReady( () => {
	
	let open = false,
        duration = 0.9,
        timing = 'cubic-bezier(0.7, 0, 0.3, 1)';
	
	var first = document.querySelector('#first');
	var	second = document.querySelector('#second');
	var	middle = document.querySelector('#middle');
	
	Moveit.put(first, {
		start: '0%',
		end: '14%',
		visibility: 1
	});
	
	Moveit.put(middle, {
		start: '0%',
		end: '100%',
		visibility: 1
	});
	
	Moveit.put(second, {
        start: '0%',
        end: '11.5%',
        visibility: 1
    });
	
	document.querySelector('.trigger').addEventListener('click', () => {
		
		if(!open){
			Moveit.animate(first, {
				start: '77.5%',
				end: '93%',
				duration: duration,
                delay: 0,
                timing: timing
			});

			Moveit.animate(middle, {
				start: '50%',
				end: '50%',
				duration: duration,
                delay: 0,
                timing: timing
			});

			Moveit.animate(second, {
				start: '81%',
				end: '94%',
				duration: duration,
                delay: 0,
                timing: timing
			});
		}else{
			Moveit.animate(first, {
				start: '0%',
				end: '14%',
				duration: duration,
                delay: 0,
                timing: timing
			});
			
			Moveit.animate(middle, {
				start: '0%',
				end: '100%',
				duration: duration,
                delay: 0,
                timing: timing
			});

			Moveit.animate(second, {
				start: '0%',
				end: '11.5%',
				duration: duration,
                delay: 0,
                timing: timing
			});
		}
		
		open = !open;
		
	});
});