import React, { Component } from 'react'
import Phone from './assets/SVG/Phone.svg'
import Mail from './assets/SVG/Mail.svg'
import Instagram from './assets/SVG/Instagram.svg'
import Facebook from './assets/SVG/Facebook.svg'

import ContactArrow from './assets/SVG/ContactArrow.svg'
import ContactArrowDown from './assets/SVG/ContactArrowDown.svg'

export default class Contacto extends Component {

    state = {
        nombre: '',
        tel: '',
        correo: '',
        mensaje: ''
    }

    handleContactForm = (event) => {

        event.preventDefault()

        function handleError(err){
			console.log(`Request failed: ${err}`);
		}

        const data = {
            nombre: this.state.nombre,
            tel: this.state.tel,
            correo: this.state.correo,
            mensaje: this.state.mensaje
        }

        let serializeinfo = `nombre=${data.nombre}&correo=${data.correo}&tel=${data.tel}&mensaje=${data.mensaje}`;

        const options = {
			method: 'POST',
			headers: {
				"Content-type": "application/x-www-form-urlencoded"
			},
			body: serializeinfo,
			credentials: 'include'
		}

        //llamada de datos con Fetch
		fetch('https://p-lao.com/mail/contacto', options)//http://localhost:8080/mail/contacto
		//fetch('https://dev-back-end.p-lao.com/mail/contacto', options)
		.then( res => {
			if(res.status === 200){
                console.log('Enviado')
				//document.querySelector(".successContact").style.display = 'block';
				//document.querySelector(".sending").style.display = 'none';
				//document.querySelector(".errorContact").style.display = 'none';
			}else{
				//document.querySelector(".errorContact").style.display = 'block';
				//document.querySelector(".sending").style.display = 'none';
				//document.querySelector(".successContact").style.display = 'none';
			}
		})
		.catch(err => handleError(err));

    }

    handleInputChange = event => {

        let id = event.target.id.replace(/-.*/g, '')

        this.setState({
           [id]: event.target.value 
        })
    }

    render() {
        return (
            <div id="contacto">

                <div id='fsContact' className='position-relative container-fluid px-0 d-none d-lg-block' style={{ backgroundColor: '#25373E', boxShadow: '0px -3px 20px -12px black' }} >
                    
                    <div className='bg-white w-50 py-5 position-absolute' style={{ width: '50%', height:'100%' , transition: 'width 1s ease', right: '0px', zIndex: 1, color: '#25373E' }} >
                        <div className='col-10 mx-auto text-right' >
                            <p className='mb-1 font-weight-bold' style={{ fontSize: '3em' }} >CONTACTANOS</p>
                            <p className='pb-3' style={{ fontSize: '1.5em' }} >GUATEMALA</p>
                            <div className='py-5' >
                                <p className='m-2' style={{ fontSize: '1.5em' }} >¿Buscas Soluciones Creativas?</p>
                                <p className='font-weight-bold' style={{ fontSize: '1.5em' }} >¡Anímate y Comunícate con Nosotros!</p>
                            </div>
                            <div className='pt-3' >
                                <a href='tel:+50256946918' className='m-3 font-weight-bold text-reset text-decoration-none' style={{ fontSize: '2.5em' }} ><img className='mr-3' style={{ height: '50px' }} src={Phone} alt='p-lao' /> +502 5694-6918</a><br/>
                                <a href='mailto:p-lao@p-lao.com' className='m-3 font-weight-bold text-reset text-decoration-none' style={{ fontSize: '1.5em' }} ><img className='mr-3' style={{ height: '30px' }} src={Mail} alt='p-lao' /> p-lao@p-lao.com</a><br/>
                                <p className='m-3 font-weight-bold' style={{ fontSize: '1.5em' }} >SIGUENOS</p>
                                <div className='m-3 ' >
                                    <a href='https://www.instagram.com/p_lao_/' target="_blank" rel = "noopener noreferrer" ><img className='' style={{ height: '40px' }} src={Instagram} alt='p-lao' /></a>
                                    <a href='https://www.facebook.com/aquepodemos' target="_blank" rel = "noopener noreferrer" ><img className='ml-3' style={{ height: '40px' }} src={Facebook} alt='p-lao' /></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src={ContactArrow} alt='p-lao' style={{ position: 'absolute', top: '23%', right: '50%', height: '260px' }} />

                    <div className='py-5 px-0 text-white' style={{ width: '50%' }} >
                        <div className='col-10 mx-auto' >
                            
                            <p className='mb-3 ' style={{ fontSize: '1.5em' }} >Completa este formulario o envíanos un correo directo a <b style={{ color: '#26B5B9' }} >p-lao@p-lao.com</b></p>
                            
                            <form onSubmit={ this.handleContactForm } >
                                <div className="form-group text-white ">
                                    <label htmlFor="nombre-tel" className='pb-2 font-weight-bold' style={{ fontSize: '2em' }} >Nombre</label>
                                    <input id="nombre-tel" onChange={ this.handleInputChange } value={this.state.nombre} type="text" className="form-control rounded-0 border-top-0 border-right-0 border-left-0 bg-transparent text-white" />
                                </div>
                                <div className="form-group text-white ">
                                    <label htmlFor="tel-tel" className='pb-2 font-weight-bold' style={{ fontSize: '2em' }} >Teléfono</label>
                                    <input id="tel-tel" onChange={this.handleInputChange} value={this.state.tel} type="text" className="form-control rounded-0 border-top-0 border-right-0 border-left-0 bg-transparent text-white" />
                                </div>
                                <div className="form-group text-white ">
                                    <label htmlFor="correo-tel" className='pb-2 font-weight-bold' style={{ fontSize: '2em' }} >Dirección de Correo</label>
                                    <input id="correo-tel" onChange={this.handleInputChange} value={this.state.correo} type="email" className="form-control rounded-0 border-top-0 border-right-0 border-left-0 bg-transparent text-white" />
                                </div>
                                <div className="form-group text-white pb-3 ">
                                    <label htmlFor="mensaje-tel" className='pb-2 font-weight-bold' style={{ fontSize: '2em' }} >Mensaje</label>
                                    <textarea id="mensaje-tel" onChange={this.handleInputChange} value={this.state.mensaje} type="text" className="form-control rounded-0 border-top-0 border-right-0 border-left-0 bg-transparent text-white" rows="3"></textarea>
                                </div>
                                <button  type="submit" className="btn btn-outline-light btn-lg btn-block font-weight-bold " style={{ fontSize: '1.5em' }}>Enviar</button >
                            </form>

                        </div>
                    </div>
                
                </div>

                <div id='Contact' className='d-lg-none' >

                    <div className='bg-white py-5 position-relative border-top' style={{ color: '#25373E', boxShadow: '0px -3px 20px -12px black' }} >
                        <div className='col-10 mx-auto text-center' >
                            <p className='mb-1 font-weight-bold' style={{ fontSize: '2.5em' }} >CONTÁCTANOS</p>
                            <p className='pb-3' style={{ fontSize: '1.5em' }} >GUATEMALA</p>
                            <div className='py-5' >
                                <p className='m-2' style={{ fontSize: '1.5em' }} >¿Buscas Soluciones Creativas?</p>
                                <p className='font-weight-bold' style={{ fontSize: '1.5em' }} >¡Anímate y Comunícate con Nosotros!</p>
                            </div>
                            <div className='pt-3' >
                                <div className='pb-4' >
                                    <a href='tel:+50256946918' className=' font-weight-bold text-reset text-decoration-none' style={{ fontSize: '1.5em' }} ><img style={{ height: '50px' }} src={Phone} alt='p-lao' /><br/> +502 5694-6918</a><br/>
                                </div>
                                <div className='py-4' >
                                    <a href='mailto:p-lao@p-lao.com' className=' font-weight-bold text-reset text-decoration-none' style={{ fontSize: '1.5em' }} ><img style={{ height: '30px' }} src={Mail} alt='p-lao' /><br/> p-lao@p-lao.com</a><br/>                                    
                                </div>
                                <div className='m-3 pb-3 ' >
                                    <p className='m-3 pt-5 font-weight-bold' style={{ fontSize: '1.5em' }} >SIGUENOS</p>
                                    <a href='https://www.instagram.com/p_lao_/' target="_blank" rel = "noopener noreferrer" ><img className='' style={{ height: '40px' }} src={Instagram} alt='p-lao' /></a>
                                    <a href='https://www.facebook.com/aquepodemos' target="_blank" rel = "noopener noreferrer" ><img className='ml-3' style={{ height: '40px' }} src={Facebook} alt='p-lao' /></a>
                                </div>
                            </div>
                        </div>

                        <img src={ContactArrowDown} alt='p-lao' style={{ position: 'absolute', top: '100%', left: 'calc(50% - 130px)', width: '260px' }} />

                    </div>

                    <div className='pt-5' style={{ backgroundColor: '#25373E', boxShadow: '0px -3px 20px -12px black' }} >
                        
                        <div className='py-5 px-0 text-white col-10 mx-auto' >

                            <p className='mb-3 ' style={{ fontSize: '1.5em' }} >Completa este formulario o envíanos un correo directo a <b style={{ color: '#26B5B9' }} >p-lao@p-lao.com</b></p>
                            
                            <form onSubmit={ this.handleContactForm } >
                                <div className="form-group text-white ">
                                    <label htmlFor="nombre-pc" className='pb-2 font-weight-bold' style={{ fontSize: '2em' }} >Nombre</label>
                                    <input id="nombre-pc" onChange={ this.handleInputChange } value={this.state.nombre}pe="text" className="form-control rounded-0 border-top-0 border-right-0 border-left-0 bg-transparent text-white" />
                                </div>
                                <div className="form-group text-white ">
                                    <label htmlFor="tel-pc" className='pb-2 font-weight-bold' style={{ fontSize: '2em' }} >Teléfono</label>
                                    <input id="tel-pc" onChange={this.handleInputChange} value={this.state.tel} type="text" className="form-control rounded-0 border-top-0 border-right-0 border-left-0 bg-transparent text-white" />
                                </div>
                                <div className="form-group text-white ">
                                    <label htmlFor="correo-pc" className='pb-2 font-weight-bold' style={{ fontSize: '2em' }} >Dirección de Correo</label>
                                    <input id="correo-pc" onChange={this.handleInputChange} value={this.state.correo} type="email" className="form-control rounded-0 border-top-0 border-right-0 border-left-0 bg-transparent text-white" />
                                </div>
                                <div className="form-group text-white pb-3 ">
                                    <label htmlFor="mensaje-pc" className='pb-2 font-weight-bold' style={{ fontSize: '2em' }} >Mensaje</label>
                                    <textarea id="mensaje-pc" onChange={this.handleInputChange} value={this.state.mensaje} type="text" className="form-control rounded-0 border-top-0 border-right-0 border-left-0 bg-transparent text-white" rows="3"></textarea>
                                </div>
                                <button  type="submit" className="btn btn-outline-light btn-lg btn-block font-weight-bold " style={{ fontSize: '1.5em' }}>Enviar</button >
                            </form>

                        </div>
                        
                    </div>

                </div>

            </div>
        )
    }
}
