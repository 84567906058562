import React from 'react';
import './css/nosotros.css';

import Video from './Video.jsx';

let Nosotros = props => (
    <section id="nosotros" className="section">
      <div id="nosotros-container" className="container-fluid">
        <div className="row">
          <div className="col-12" style={{paddingLeft: '0px', paddingRight: '0px', marginTop: '65px'}}>
            <div className="container-fluid">

              <div className="row">

                <div id="container-nosotros-text" className="justify-content-center col-12">
                  <h2 className="text-center">SOMOS  CREADORES DE INNOVACIÓN</h2>
                  <p className="text-justify col-12 col-md-9 col-lg-6 mx-auto">Encontramos soluciones creativas e innovadoras, que establecen relaciones entre marcas y personas, resolviendo problemáticas de comunicación y productividad.</p>
                  <p className="text-justify col-12 col-md-9 col-lg-6 mx-auto">Somos una empresa dedicada en brindar soluciones creativas innovadoras y adaptables a las necesidades de los clientes integrando herramientas de Comunicación Visual, Marketing y Desarrollo Web, a través de una asesoría profesional, solucionando la problemática de comunicación y eficiencia del cliente.</p>
                </div>

                <div id="container-nosotros-servicios" className="col-12 text-center">
                  <button>Servicios</button>
                </div>

              </div>

              <Video />

            </div>
          </div>
        </div>
      </div>
    </section>
)

export default Nosotros