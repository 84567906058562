import React from 'react';
import { Route, Switch} from 'react-router-dom';//Agregar Redirect cuando se use

//Components
import AppLayout from './AppLayout.jsx';
import Footer from './UniversalComponents/Footer.jsx';

//Inicio
import Inicio from '../Inicio/Components/Inicio.jsx';

const PrincipalRoutes = (props) => {
	//console.log(props);
	return (
		<AppLayout>
			<Switch>
				{/*Inicio*/}
				<Route path="/" exact render={ ({ location }) => <Inicio location={location} /> } />	
			</Switch>
			{/*Footer*/}
			<Footer />
		</AppLayout>
	)
}

export default PrincipalRoutes;