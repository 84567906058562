import React, { Component } from 'react';

import Background from './assets/servicesImage.png'
import Web from './assets/SVG/Web.svg'
import Branding from './assets/SVG/Branding.svg'
import Marketing from './assets/SVG/Marketing.svg'
import Editorial from './assets/SVG/Editorial.svg'
import VerticalCurve from './assets/SVG/ServicesArrow.svg'

import WebExpand from './assets/SVG/WebExpand.svg'
import MarketingExpand from './assets/SVG/MarketingExpand.svg'
import BrandingExpand from './assets/SVG/BrandingExpand.svg'
import EditorialExpand from './assets/SVG/EditorialExpand.svg'

import Slider from 'react-slick'
import Modal from 'react-bootstrap4-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import '../../../iconP-lao/style.css'
import './css/servicios.css'

class Servicios extends Component {

  state = {
    fsWebToggle: false,
    fsMarketingToggle: false,
    fsBrandingToggle: false,
    fsEditorialToggle: false,
    WebToggle: false,
    MarketingToggle: false,
    BrandingToggle: false,
    EditorialToggle: false,
    WebModal: false,
    MarketingModal: false,
    BrandingModal: false,
    EditorialModal: false,
  }

  setWrapperRef = (node) => {
    if ( node.id === 'fsWrapper' ) { 
      this.wrapperRef = node 
    } else if ( node.id === 'fsWebToggle' ) {
      this.fsWebToggleRef = node
    } else if ( node.id === 'fsMarketingToggle' ) {
      this.fsMarketingToggleRef = node
    } else if ( node.id === 'fsBrandingToggle' ) {
      this.fsBrandingToggleRef = node
    } else if ( node.id === 'fsEditorialToggle' ) {
      this.fsEditorialToggleRef = node
    } else if ( node.id === 'WebToggle' ) {
      this.WebToggleRef = node
    } else if ( node.id === 'MarketingToggle' ) {
      this.MarketingToggleRef = node
    } else if ( node.id === 'BrandingToggle' ) {
      this.BrandingToggleRef = node
    } else if ( node.id === 'EditorialToggle' ) {
      this.EditorialToggleRef = node
    }
    
  }

  simulateClick = (el, etype) => {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.state.fsWebToggle === true && this.simulateClick(document.getElementById('fsWebToggle'), 'click')
      this.state.fsMarketingToggle === true && this.simulateClick(document.getElementById('fsMarketingToggle'), 'click')
      this.state.fsBrandingToggle === true && this.simulateClick(document.getElementById('fsBrandingToggle'), 'click')
      this.state.fsEditorialToggle === true && this.simulateClick(document.getElementById('fsEditorialToggle'), 'click')
    }
  }

  handleExpandButton = id => {
    document.getElementById(`${id}`).style.left = '55%'
    document.getElementById('fsservicesMenu').style.width = '50%';
  }

  handleExpandCloseButton = id => {
    document.getElementById(`${id}`).style.left = '100%'
    document.getElementById('fsservicesMenu').style.width = '80%';
  }

  handleToggle = (event) => {

    if ( this.fsWebToggleRef.contains(event.target) ) {
      this.setState({ fsWebToggle: !this.state.fsWebToggle })
      this.state.fsMarketingToggle === true && this.simulateClick(document.getElementById('fsMarketingToggle'), 'click')
      this.state.fsBrandingToggle === true && this.simulateClick(document.getElementById('fsBrandingToggle'), 'click')
      this.state.fsEditorialToggle === true && this.simulateClick(document.getElementById('fsEditorialToggle'), 'click')
    } else if ( this.fsMarketingToggleRef.contains(event.target) ) {
      this.setState({ fsMarketingToggle: !this.state.fsMarketingToggle })
      this.state.fsWebToggle === true && this.simulateClick(document.getElementById('fsWebToggle'), 'click')
      this.state.fsBrandingToggle === true && this.simulateClick(document.getElementById('fsBrandingToggle'), 'click')
      this.state.fsEditorialToggle === true && this.simulateClick(document.getElementById('fsEditorialToggle'), 'click')
    } else if ( this.fsBrandingToggleRef.contains(event.target) ) {
      this.setState({ fsBrandingToggle: !this.state.fsBrandingToggle })
      this.state.fsWebToggle === true && this.simulateClick(document.getElementById('fsWebToggle'), 'click')
      this.state.fsMarketingToggle === true && this.simulateClick(document.getElementById('fsMarketingToggle'), 'click')
      this.state.fsEditorialToggle === true && this.simulateClick(document.getElementById('fsEditorialToggle'), 'click')
    } else if ( this.fsEditorialToggleRef.contains(event.target) ) {
      this.setState({ fsEditorialToggle: !this.state.fsEditorialToggle })
      this.state.fsWebToggle === true && this.simulateClick(document.getElementById('fsWebToggle'), 'click')
      this.state.fsMarketingToggle === true && this.simulateClick(document.getElementById('fsMarketingToggle'), 'click')
      this.state.fsBrandingToggle === true && this.simulateClick(document.getElementById('fsBrandingToggle'), 'click')
    } else if ( this.WebToggleRef.contains(event.target) ) {
      this.setState({ WebToggle: !this.state.WebToggle })
      this.state.MarketingToggle === true && this.simulateClick(document.getElementById('MarketingToggle'), 'click')
      this.state.BrandingToggle === true && this.simulateClick(document.getElementById('BrandingToggle'), 'click')
      this.state.EditorialToggle === true && this.simulateClick(document.getElementById('EditorialToggle'), 'click')
    } else if ( this.MarketingToggleRef.contains(event.target) ) {
      this.setState({ MarketingToggle: !this.state.MarketingToggle })
      this.state.WebToggle === true && this.simulateClick(document.getElementById('WebToggle'), 'click')
      this.state.BrandingToggle === true && this.simulateClick(document.getElementById('BrandingToggle'), 'click')
      this.state.EditorialToggle === true && this.simulateClick(document.getElementById('EditorialToggle'), 'click')
    } else if ( this.BrandingToggleRef.contains(event.target) ) {
      this.setState({ BrandingToggle: !this.state.BrandingToggle })
      this.state.WebToggle === true && this.simulateClick(document.getElementById('WebToggle'), 'click')
      this.state.MarketingToggle === true && this.simulateClick(document.getElementById('MarketingToggle'), 'click')
      this.state.EditorialToggle === true && this.simulateClick(document.getElementById('EditorialToggle'), 'click')
    } else if ( this.EditorialToggleRef.contains(event.target) ) {
      this.setState({ EditorialToggle: !this.state.EditorialToggle })
      this.state.WebToggle === true && this.simulateClick(document.getElementById('WebToggle'), 'click')
      this.state.MarketingToggle === true && this.simulateClick(document.getElementById('MarketingToggle'), 'click')
      this.state.BrandingToggle === true && this.simulateClick(document.getElementById('BrandingToggle'), 'click')
    }
  }

  componentDidUpdate() {

    if (
      this.state.fsWebToggle === false &&
      this.state.fsMarketingToggle === false &&
      this.state.fsBrandingToggle === false &&
      this.state.fsEditorialToggle === false
    ) { document.getElementById('fsservicesMenu').style.width = '50%' } else if (
      this.state.fsWebToggle === true ||
      this.state.fsMarketingToggle === true ||
      this.state.fsBrandingToggle === true ||
      this.state.fsEditorialToggle === true
    ) { document.getElementById('fsservicesMenu').style.width = '80%' } 

    if (this.state.fsWebToggle === true) { document.getElementById('fsWebChevronUp').style.transform = 'rotate(180deg)' } else { document.getElementById('fsWebChevronUp').style.transform = 'rotate(0deg)' }
    if (this.state.fsMarketingToggle === true) { document.getElementById('fsMarketingChevronUp').style.transform = 'rotate(180deg)' } else { document.getElementById('fsMarketingChevronUp').style.transform = 'rotate(0deg)' }
    if (this.state.fsBrandingToggle === true) { document.getElementById('fsBrandingChevronUp').style.transform = 'rotate(180deg)' } else { document.getElementById('fsBrandingChevronUp').style.transform = 'rotate(0deg)' }
    if (this.state.fsEditorialToggle === true) { document.getElementById('fsEditorialChevronUp').style.transform = 'rotate(180deg)' } else { document.getElementById('fsEditorialChevronUp').style.transform = 'rotate(0deg)' }
    
    if (this.state.WebToggle === true) { document.getElementById('WebChevronUp').style.transform = 'rotate(180deg)' } else { document.getElementById('WebChevronUp').style.transform = 'rotate(0deg)' }
    if (this.state.MarketingToggle === true) { document.getElementById('MarketingChevronUp').style.transform = 'rotate(180deg)' } else { document.getElementById('MarketingChevronUp').style.transform = 'rotate(0deg)' }
    if (this.state.BrandingToggle === true) { document.getElementById('BrandingChevronUp').style.transform = 'rotate(180deg)' } else { document.getElementById('BrandingChevronUp').style.transform = 'rotate(0deg)' }
    if (this.state.EditorialToggle === true) { document.getElementById('EditorialChevronUp').style.transform = 'rotate(180deg)' } else { document.getElementById('EditorialChevronUp').style.transform = 'rotate(0deg)' }

    document.getElementById('fsWebExpand').style.left = '100%'
    document.getElementById('fsMarketingExpand').style.left = '100%'
    document.getElementById('fsBrandingExpand').style.left = '100%'
    document.getElementById('fsEditorialExpand').style.left = '100%'

  }

  render() {

    document.addEventListener('mousedown', this.handleClickOutside);

    let SampleNextArrow = (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, display: "block", background: "transparent" , right: '0px', zIndex: '50', color: '#FFFFFF'}}
          onClick={onClick}
        />
      );
    }
    
    let SamplePrevArrow = (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, display: "block", background: "transparent", left: '0px', zIndex: '50', color: '#FFFFFF'}}
          onClick={onClick}
        />
      );
    }

    const settings = {
      autoplay: true,
      autoplaySpeed: 4000,
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    }

    return (
      <section id='servicios'>

        <div id='fsWrapper' ref={ this.setWrapperRef } className="position-relative container-fluid px-0 d-none d-lg-block" style={{ background: `url(${Background}) no-repeat local right/auto` }}   >

          <div id='fsservicesMenu' style={{ width: '50%', transition: 'width 1s ease', backgroundColor: '#25373E' }}>
            
            <div className='position-relative' >

              <div id='servicesCollapse' className="col-md-10 mx-auto text-white py-5 accordion">
        
                <div id='fsWebDevelopment' className='text-left pt-5 ' >

                  <div id='fsWebToggle' ref={this.setWrapperRef} onClick={ this.handleToggle } className="row py-4" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#fsWebCollapsible" >
                  
                    <div className="col-12 col-md-2 containerIcon d-flex justify-content-center align-items-center " >
                      <img style={{ height: '75px', marginRight: '30px' }} src={Web} alt='iconoSocialMedia' />
                    </div>
                    
                    <div className="col-12 col-md-10 d-flex flex-row align-items-center">
                      <h3 className='m-0' >DISEÑO Y DESARROLLO WEB</h3>
                      <FontAwesomeIcon id='fsWebChevronUp' className='ml-4 d-none d-md-block' style={{ width: '25px', transition: 'transform 0.5s ease' }} icon={"chevron-down"} />
                    </div>

                  </div>
                  
                  <div id='fsWebCollapsible' className='collapse' 
                    // data-parent="#servicesCollapse" 
                  >
                    <hr className='bg-white m-0' style={{ width: '70%' }} />
                    <p className='m-0 mt-4' >El diseño web es una actividad que consiste en la planificación, diseño, implementación y mantenimiento de aplicaciones web.</p>
                    <div className='text-right' >
                      <button onClick={ () => this.handleExpandButton('fsWebExpand') } type="button" className="btn btn-info my-4 rounded-pill ml-auto ">CONOCE <b>MÁS</b></button>
                    </div>
                  </div>

                </div>

                <div id='fsMarketing' className='text-left pt-5 ' >

                  <div id='fsMarketingToggle' ref={this.setWrapperRef} onClick={ this.handleToggle } className="row py-4" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#fsMarketingCollapsible"   >
                  
                    <div className="col-12 col-md-2 containerIcon d-flex justify-content-center align-items-center ">
                      <img style={{ height: '75px', marginRight: '30px' }} src={Marketing} alt='iconoMarketing' />
                    </div>
                    
                    <div className="col-12 col-md-10 d-flex flex-row align-items-center">
                      <h3 className='m-0' >MARKETING & MULTIMEDIA</h3>
                      <FontAwesomeIcon id='fsMarketingChevronUp' className='ml-4 d-none d-md-block' style={{ width: '25px', transition: 'transform 0.5s ease' }} icon={"chevron-down"} />
                    </div>

                  </div>
                  
                  <div id='fsMarketingCollapsible' 
                    className='collapse' 
                    // data-parent="#servicesCollapse" 
                  >
                    <hr className='bg-white m-0' style={{ width: '70%' }} />
                    <p className='m-0 mt-4' >Es el conjunto de actividades que ejecutamos en línea con el objetivo de atraer nuevos negocios, crear relaciones y desarrollar una identidad de marca. Dentro de las estrategias están el SEO y SEM, social media y desarrollo de campañas publicitarias.</p>
                    <div className='text-right' >
                      <button type="button" onClick={ () => this.handleExpandButton('fsMarketingExpand') } className="btn btn-info my-4 rounded-pill ml-auto ">CONOCE <b>MÁS</b></button>
                    </div>
                  </div>

                </div>

                <div id='fsBranding' className='text-left pt-5 ' >

                  <div id='fsBrandingToggle' ref={this.setWrapperRef} onClick={ this.handleToggle } className="row py-4" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#fsBrandingCollapsible"   >
                  
                    <div className="col-12 col-md-2 containerIcon d-flex justify-content-center align-items-center ">
                      <img style={{ height: '75px', marginRight: '30px' }} src={Branding} alt='iconoMarketing' />
                    </div>
                    
                    <div className="col-12 col-md-10 d-flex flex-row align-items-center">
                      <h3 className='m-0' >BRANDING</h3>
                      <FontAwesomeIcon id='fsBrandingChevronUp' className='ml-4 d-none d-md-block' style={{ width: '25px', transition: 'transform 0.5s ease' }} icon={"chevron-down"} />
                    </div>

                  </div>
                  
                  <div id='fsBrandingCollapsible' 
                    className='collapse' 
                    // data-parent="#servicesCollapse" 
                  >
                    <hr className='bg-white m-0' style={{ width: '70%' }} />
                    <p className='m-0 mt-4' >Es el proceso de hacer y construir una marca mediante la administración estratégica de un conjunto de elementos, que identifican a un producto por medio de un nombre, símbolo (logotipo) influyendo esto en el valor del producto y servicio para la empresa.</p>
                    <div className='text-right' >
                      <button type="button" onClick={ () => this.handleExpandButton('fsBrandingExpand') } className="btn btn-info my-4 rounded-pill ml-auto ">CONOCE <b>MÁS</b></button>
                    </div>
                  </div>

                </div>
                
                <div id='fsEditorial' className='text-left py-5 ' >

                  <div id='fsEditorialToggle' ref={this.setWrapperRef} onClick={ this.handleToggle } className="row py-4" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#fsEditorialCollapsible"   >
                  
                    <div className="col-12 col-md-2 containerIcon d-flex justify-content-center align-items-center ">
                      <img style={{ height: '75px', marginRight: '30px' }} src={Editorial} alt='iconoEditorial' />
                    </div>
                    
                    <div className="col-12 col-md-10 d-flex flex-row align-items-center">
                      <h3 className='m-0' >DISEÑO EDITORIAL</h3>
                      <FontAwesomeIcon id='fsEditorialChevronUp' className='ml-4 d-none d-md-block' style={{ width: '25px', transition: 'transform 0.5s ease' }} icon={"chevron-down"} />
                    </div>

                  </div>
                  
                  <div id='fsEditorialCollapsible' 
                    className='collapse' 
                    // data-parent="#servicesCollapse" 
                  >
                    <hr className='bg-white m-0' style={{ width: '70%' }} />
                    <p className='m-0 mt-4' >Es la producción y difusión de todo tipo de libros (literarios, técnicos, enciclopédicos, de entretenimiento, cómics, informativos, etc.).</p>
                    <div className='text-right' >
                      <button type="button" onClick={ () => this.handleExpandButton('fsEditorialExpand') } className="btn btn-info my-4 rounded-pill ml-auto ">CONOCE <b>MÁS</b></button>
                    </div>
                  </div>

                </div>

              </div>

              <img src={VerticalCurve} alt='p-lao' style={{ position: 'absolute', top: '23%', left: '99%', height: '260px' }} />

            </div>        
      
          </div>
          
          <div id='fsWebExpand' className='position-absolute bg-white' style={{ width: '42%', height: '92%', top: '3%', left: '100%', transition: 'left 1s ease' }} >
            
            <div className='text-white py-3 px-5 rounded-top position-relative' style={{ backgroundColor: '#25373E', fontSize: '1.2em' }} >
              <FontAwesomeIcon onClick={ () => this.handleExpandCloseButton('fsWebExpand') } style={{ position: 'absolute', width: '25px', right: '2%', color: 'white', cursor: 'pointer' }} icon={faTimes} />
              Diseño y <br/><b>Desarrollo Web</b>
            </div>
            <div className='d-flex' >
              <div className='w-50 py-1' style={{ backgroundColor: '#26B5B9' }} ></div>
              <div className='w-50 py-1' style={{ backgroundColor: '#FF9E00' }} ></div>
            </div>
            <div className='bg-white px-5 py-3 rounded-bottom' style={{ fontSize: '.85em' }} >
              <div className='row' >
                <div className='col-4 d-flex justify-content-center align-items-center' >
                  <img className='img-fluid' style={{ maxHeight: '100px' }} src={WebExpand} alt='p-lao' />
                </div>
                <div className='col-8' >
                  <p>El sitio web es la presentación de la empresa que gestionas en los medios digitales. Cada vez una persona acude a tu empresa, te preocupas hacer una buena primera impresión.</p>
                  <p>Bueno, con tu sitio, la situación no es diferente. Debe ser un reflejo inmediato del negocio.</p>
                </div>
              </div>
              <p>Un sitio web que no sea profesional hace que los visitantes no tengan una buena experiencia e incluso piensen que la empresa está desactualizada. Esto hará que busquen ofertas como la tuya en competidores que tienen un mejor sitio web.</p>
              <p>Se abarcan diferentes aspectos como: </p>
              <ul>
                <li>Diseño gráfico web</li>
                <li>Diseño de interfaz</li>
                <li>
                  Experiencia de usuario<br/>
                  <ul>
                    <li>La navegabilidad</li>
                    <li>Interactividad</li>
                    <li>Usabilidad</li>
                    <li>Arquitectura de la información</li>
                  </ul>
                </li>
                <li>
                  Interacción de medios
                  <ul>
                    <li>Audio</li>
                    <li>Texto</li>
                    <li>Imagen</li>
                    <li>Enlaces</li>
                    <li>Video</li>
                    <li>Optimización de motores de búsqueda</li>
                  </ul>
                </li>

              </ul>
            </div>
            
          </div>

          <div id='fsMarketingExpand' className='position-absolute bg-white' style={{ width: '42%', height: '92%', top: '3%', left: '100%', transition: 'left 1s ease' }} >
            
            <div className='text-white py-3 px-5 rounded-top position-relative' style={{ backgroundColor: '#25373E', fontSize: '1.4em' }} >
              <FontAwesomeIcon onClick={ () => this.handleExpandCloseButton('fsMarketingExpand') } style={{ position: 'absolute', width: '25px', right: '2%', color: 'white', cursor: 'pointer' }} icon={faTimes} />
              ADMINISTRACIÓN DE <br/><b>MEDIOS DIGITALES</b>
            </div>
            <div className='d-flex' >
              <div className='w-50 py-1' style={{ backgroundColor: '#26B5B9' }} ></div>
              <div className='w-50 py-1' style={{ backgroundColor: '#FF9E00' }} ></div>
            </div>
            <div className='bg-white px-5 py-3 rounded-bottom' style={{ fontSize: '1em' }} >
              <div className='' >
                <div className='row' >
                  <div className='col-4 d-flex justify-content-center align-items-center' >
                    <img className='img-fluid' style={{ maxHeight: '100px' }} src={MarketingExpand} alt='p-lao' />
                  </div>
                  <div className='col-8' >
                    <p>Ideal para aquellas empresas que ya comenzaron a laborar, que tienen la necesidad de formar una imagen digital, publicitar, y generar estrategias comunicativas en medios sociales, tienendo un alcance más amplio y acercándose a su mercado objetivo.</p>                  </div>
                </div>
                <ul>
                  <li>Diagnóstico (selección de medios digitales)</li>
                  <li>Definición de objetivos, estrategias y tácticas.</li>
                  <li>Envío de planificación y cronograma.</li>
                  <li>Diseño de publicidad.</li>
                  <li>Reuniones semanales o mensuales.</li>
                  <li>Informes</li>
                  <li>Publicación de contenido</li>
                  <li>Administración de redes sociales (respuesta a personas e interacción)</li>
                </ul>
                <div>
                  <p className='font-weight-bold' style={{ fontSize: '1.2em' }} >ALGUNOS TRABAJOS</p>
                  <Slider {...settings} className="galeria-inicio">
                    <div className="">
                      ventana 1
                    </div>
                    <div className="">
                        ventana 2
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
            
          </div>
          
          <div id='fsBrandingExpand' className='position-absolute bg-white' style={{ width: '42%', height: '92%', top: '3%', left: '100%', transition: 'left 1s ease' }} >
            
            <div className='text-white py-3 px-5 rounded-top position-relative' style={{ backgroundColor: '#25373E', fontSize: '1.2em' }} >
              <FontAwesomeIcon onClick={ () => this.handleExpandCloseButton('fsBrandingExpand') } style={{ position: 'absolute', width: '25px', right: '2%', color: 'white', cursor: 'pointer' }} icon={faTimes} />
              Diseño y <br/><b>Desarrollo Web</b>
            </div>
            <div className='d-flex' >
              <div className='w-50 py-1' style={{ backgroundColor: '#26B5B9' }} ></div>
              <div className='w-50 py-1' style={{ backgroundColor: '#FF9E00' }} ></div>
            </div>
            <div className='bg-white px-5 py-3 rounded-bottom' style={{ fontSize: '.85em' }} >
              <div className='' >
                <div className='row' >
                  <div className='col-4 d-flex justify-content-center align-items-center' >
                    <img className='img-fluid' style={{ maxHeight: '100px' }} src={BrandingExpand} alt='p-lao' />
                  </div>
                  <div className='col-8' >
                    <p>Es la columna vertebral o los cimientos del desarrollo de toda publicación publicitaria de la marca. En él se encuentra la regulación del uso de la marca.</p>
                    <p>Este manual es indispensable en cualquier empresa para el desarrollo adecuado de su publicidad e imagen.</p>
                  </div>
                </div>
                <ul>
                  <li>Introducción</li>
                  <li>Índice
                    <ul>
                      <li>La navegabilidad</li>
                      <li>Interactividad</li>
                      <li>Usabilidad</li>
                      <li>Arquitectura de la información</li>
                    </ul>
                  </li>
                  <li>Red de construcción</li>
                  <li>Área de aislamiento</li>
                  <li>Tipografía Institucional</li>
                  <li>TIpografía Alternativa</li>
                  <li>Restricciones Tipográficas</li>
                  <li>Sistema cromático (Pantone, CMYK y RGB)</li>
                  <li>Restricciones en el manejo del color</li>
                  <li>Tamaños permitidos (Tabla proxémica)</li>
                  <li>Papelería Básica</li>
                  <li>Tarjeta de Presentación Corporativa</li>
                  <li>Hoja Carta Primera</li>
                  <li>Sobre Tamaño Carta</li>
                </ul>
                <div>
                  <p className='font-weight-bold' style={{ fontSize: '1.2em' }} >ALGUNOS TRABAJOS</p>
                  <Slider {...settings} className="galeria-inicio">
                    <div className="">
                      ventana 1
                    </div>
                    <div className="">
                        ventana 2
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
            
          </div>

          <div id='fsEditorialExpand' className='position-absolute bg-white' style={{ width: '42%', height: '92%', top: '3%', left: '100%', transition: 'left 1s ease' }} >
            
            <div className='text-white py-3 px-5 rounded-top position-relative' style={{ backgroundColor: '#25373E', fontSize: '1.2em' }} >
              <FontAwesomeIcon onClick={ () => this.handleExpandCloseButton('fsEditorialExpand') } style={{ position: 'absolute', width: '25px', right: '2%', color: 'white', cursor: 'pointer' }} icon={faTimes} />
              <b>DISEÑO EDITORIAL</b>
            </div>
            <div className='d-flex' >
              <div className='w-50 py-1' style={{ backgroundColor: '#26B5B9' }} ></div>
              <div className='w-50 py-1' style={{ backgroundColor: '#FF9E00' }} ></div>
            </div>
            <div className='bg-white px-5 py-3 rounded-bottom' style={{ fontSize: '1em' }} >
              <div className='' >
                <div className='row' >
                  <div className='col-4 d-flex justify-content-center align-items-center' >
                    <img className='img-fluid' style={{ maxHeight: '100px' }} src={EditorialExpand} alt='p-lao' />
                  </div>
                  <div className='col-8' >
                    <p>Es una rama del diseño gráfico que se dedica a la diagramación y producción de diferentes piezas, como libros, revistas, periódicos, catálogos, etc.</p>
                  </div>
                </div>
                <br />
                <ul>
                  <li>Catálogo o revista</li>
                  <li>Diseño de folleto</li>
                  <li>Manual normativo o instructivo</li>
                  <li>Diseño de troquelado y empaques</li>
                </ul>
                <div>
                  <p className='font-weight-bold' style={{ fontSize: '1.2em' }} >ALGUNOS TRABAJOS</p>
                  <Slider {...settings} className="galeria-inicio">
                    <div className="">
                      ventana 1
                    </div>
                    <div className="">
                        ventana 2
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
            
          </div>

        </div>

        <div id='wrapper' className="container-fluid px-0 d-lg-none" style={{ background: `url(${Background}) no-repeat local right/auto` }}   >

          <div id='servicesMenu' style={{ width: '100%', transition: 'width 1s ease', backgroundColor: '#25373E' }}>

            <div id='servicesCollapse' className="col-md-10 mx-auto text-white py-5 accordion">
      
              <div id='WebDevelopment' className='text-left pt-5 ' >

                <div id='WebToggle' ref={ this.setWrapperRef } onClick={ this.handleToggle } className="py-4 row text-center d-flex align-items-center " style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#WebCollapsible" >
                
                  <img className='col-12 col-md-2 my-2 ' style={{ height: '75px' }} src={Web} alt='iconoSocialMedia' />
                  <h3 className='col-12 col-md-8 m-0 my-2 ' >DISEÑO Y DESARROLLO WEB</h3>
                  <FontAwesomeIcon id='WebChevronUp' className='col-12 col-md-2 my-2 ' style={{ height: '25px', transition: 'transform 0.5s ease' }} icon={"chevron-down"} />

                </div>
                
                <div id='WebCollapsible' className='collapse text-center' 
                  // data-parent="#servicesCollapse" 
                >
                  <hr className='bg-white mx-auto ' style={{ width: '70%' }} />
                  <p className='m-0 mt-4' >El diseño web es una actividad que consiste en la planificación, diseño, implementación y mantenimiento de aplicaciones web.</p>
                  <button onClick={ () => this.setState({ WebModal: true }) } type="button" className="btn btn-info my-4 rounded-pill ml-auto ">CONOCE <b>MÁS</b></button>
                </div>

              </div>
              
              <div id='Marketing' className='text-left pt-5 ' >

                <div id='MarketingToggle' ref={ this.setWrapperRef } onClick={ this.handleToggle } className="py-4 row text-center d-flex align-items-center " style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#MarketingCollapsible" >
                
                  <img className='col-12 col-md-2 my-2 ' style={{ height: '75px' }} src={Marketing} alt='iconoSocialMedia' />
                  <h3 className='col-12 col-md-8 m-0 my-2 ' >MARKETING & MULTIMEDIA</h3>
                  <FontAwesomeIcon id='MarketingChevronUp' className='col-12 col-md-2 my-2 ' style={{ height: '25px', transition: 'transform 0.5s ease' }} icon={"chevron-down"} />

                </div>
                
                <div id='MarketingCollapsible' className='collapse text-center' 
                  // data-parent="#servicesCollapse" 
                >
                  <hr className='bg-white mx-auto ' style={{ width: '70%' }} />
                  <p className='m-0 mt-4' >Es el conjunto de actividades que ejecutamos en línea con el objetivo de atraer nuevos negocios, crear relaciones y desarrollar una identidad de marca. Dentro de las estrategias están el SEO y SEM, social media y desarrollo de campañas publicitarias.</p>
                  <button onClick={ () => this.setState({ MarketingModal: true }) } type="button" className="btn btn-info my-4 rounded-pill ml-auto ">CONOCE <b>MÁS</b></button>
                </div>

              </div>

              <div id='Branding' className='text-left pt-5 ' >

                <div id='BrandingToggle' ref={ this.setWrapperRef } onClick={ this.handleToggle } className="py-4 row text-center d-flex align-items-center " style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#BrandingCollapsible" >
                
                  <img className='col-12 col-md-2 my-2 ' style={{ height: '75px' }} src={Branding} alt='iconoSocialMedia' />
                  <h3 className='col-12 col-md-8 m-0 my-2 ' >BRANDING</h3>
                  <FontAwesomeIcon id='BrandingChevronUp' className='col-12 col-md-2 my-2 ' style={{ height: '25px', transition: 'transform 0.5s ease' }} icon={"chevron-down"} />

                </div>
                
                <div id='BrandingCollapsible' className='collapse text-center' 
                  // data-parent="#servicesCollapse" 
                >
                  <hr className='bg-white mx-auto ' style={{ width: '70%' }} />
                  <p className='m-0 mt-4' >Es el proceso de hacer y construir una marca mediante la administración estratégica de un conjunto de elementos, que identifican a un producto por medio de un nombre, símbolo (logotipo) influyendo esto en el valor del producto y servicio para la empresa.</p>
                  <button onClick={ () => this.setState({ BrandingModal: true }) } type="button" className="btn btn-info my-4 rounded-pill ml-auto ">CONOCE <b>MÁS</b></button>
                </div>

              </div>

              <div id='Editorial' className='text-left py-5 ' >

                <div id='EditorialToggle' ref={ this.setWrapperRef } onClick={ this.handleToggle } className="py-4 row text-center d-flex align-items-center " style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#EditorialCollapsible" >
                
                  <img className='col-12 col-md-2 my-2 ' style={{ height: '75px' }} src={Editorial} alt='iconoSocialMedia' />
                  <h3 className='col-12 col-md-8 m-0 my-2 ' >DISEÑO EDITORIAL</h3>
                  <FontAwesomeIcon id='EditorialChevronUp' className='col-12 col-md-2 my-2 ' style={{ height: '25px', transition: 'transform 0.5s ease' }} icon={"chevron-down"} />

                </div>
                
                <div id='EditorialCollapsible' className='collapse text-center' 
                  // data-parent="#servicesCollapse" 
                >
                  <hr className='bg-white mx-auto ' style={{ width: '70%' }} />
                  <p className='m-0 mt-4' >Es la producción y difusión de todo tipo de libros (literarios, técnicos, enciclopédicos, de entretenimiento, cómics, informativos, etc.).</p>
                  <button onClick={ () => this.setState({ EditorialModal: true }) } type="button" className="btn btn-info my-4 rounded-pill ml-auto ">CONOCE <b>MÁS</b></button>
                </div>

              </div>
              
            </div>

            <Modal id='WebModal' visible={this.state.WebModal} fade onClickBackdrop={ () => this.setState({ WebModal: false }) } dialogClassName={ 'modal-dialog modal-dialog-centered' } >
              
              <div className='text-white py-3 px-5 rounded-top position-relative' style={{ backgroundColor: '#25373E', fontSize: '1.2em' }} >
                <FontAwesomeIcon onClick={ () => this.setState({ WebModal: false }) } style={{ position: 'absolute', width: '25px', right: '2%', color: 'white', cursor: 'pointer' }} icon={faTimes} />
                Diseño y <br/><b>Desarrollo Web</b>
              </div>
              <div className='d-flex' >
                <div className='w-50 py-1' style={{ backgroundColor: '#26B5B9' }} ></div>
                <div className='w-50 py-1' style={{ backgroundColor: '#FF9E00' }} ></div>
              </div>
              <div className='bg-white col-11 mx-auto py-3 rounded-bottom' style={{ fontSize: '.85em' }} >
                <div className='row' >
                  <div className='col-4 d-flex justify-content-center align-items-center' >
                    <img className='img-fluid' style={{ maxHeight: '100px' }} src={WebExpand} alt='p-lao' />
                  </div>
                  <div className='col-8' >
                    <p>El sitio web es la presentación de la empresa que gestionas en los medios digitales. Cada vez una persona acude a tu empresa, te preocupas hacer una buena primera impresión.</p>
                    <p>Bueno, con tu sitio, la situación no es diferente. Debe ser un reflejo inmediato del negocio.</p>
                  </div>
                </div>
                <p>Un sitio web que no sea profesional hace que los visitantes no tengan una buena experiencia e incluso piensen que la empresa está desactualizada. Esto hará que busquen ofertas como la tuya en competidores que tienen un mejor sitio web.</p>
                <p>Se abarcan diferentes aspectos como: </p>
                <ul>
                  <li>Diseño gráfico web</li>
                  <li>Diseño de interfaz</li>
                  <li>
                    Experiencia de usuario<br/>
                    <ul>
                      <li>La navegabilidad</li>
                      <li>Interactividad</li>
                      <li>Usabilidad</li>
                      <li>Arquitectura de la información</li>
                    </ul>
                  </li>
                  <li>
                    Interacción de medios
                    <ul>
                      <li>Audio</li>
                      <li>Texto</li>
                      <li>Imagen</li>
                      <li>Enlaces</li>
                      <li>Video</li>
                      <li>Optimización de motores de búsqueda</li>
                    </ul>
                  </li>

                </ul>
              </div>

            </Modal>

            <Modal id='MarketingModal' visible={this.state.MarketingModal} fade onClickBackdrop={ () => this.setState({ MarketingModal: false })} dialogClassName={ 'modal-dialog modal-dialog-centered' } >
              
              <div className='text-white py-3 px-5 rounded-top position-relative' style={{ backgroundColor: '#25373E', fontSize: '1.4em' }} >
                <FontAwesomeIcon onClick={ () => this.setState({ MarketingModal: false }) } style={{ position: 'absolute', width: '25px', right: '2%', color: 'white', cursor: 'pointer' }} icon={faTimes} />
                ADMINISTRACIÓN DE <br/><b>MEDIOS DIGITALES</b>
              </div>
              <div className='d-flex' >
                <div className='w-50 py-1' style={{ backgroundColor: '#26B5B9' }} ></div>
                <div className='w-50 py-1' style={{ backgroundColor: '#FF9E00' }} ></div>
              </div>
              <div className='bg-white px-5 py-3 rounded-bottom' style={{ fontSize: '1em' }} >
                <div className='' >
                  <div className='row' >
                    <div className='col-4 d-flex justify-content-center align-items-center' >
                      <img className='img-fluid' style={{ maxHeight: '100px' }} src={MarketingExpand} alt='p-lao' />
                    </div>
                    <div className='col-8' >
                      <p>Ideal para aquellas empresas que ya comenzaron a laborar, que tienen la necesidad de formar una imagen digital, publicitar, y generar estrategias comunicativas en medios sociales, tienendo un alcance más amplio y acercándose a su mercado objetivo.</p>                  </div>
                  </div>
                  <ul>
                    <li>Diagnóstico (selección de medios digitales)</li>
                    <li>Definición de objetivos, estrategias y tácticas.</li>
                    <li>Envío de planificación y cronograma.</li>
                    <li>Diseño de publicidad.</li>
                    <li>Reuniones semanales o mensuales.</li>
                    <li>Informes</li>
                    <li>Publicación de contenido</li>
                    <li>Administración de redes sociales (respuesta a personas e interacción)</li>
                  </ul>
                  <div>
                    <p className='font-weight-bold' style={{ fontSize: '1.2em' }} >ALGUNOS TRABAJOS</p>
                    <Slider {...settings} className="galeria-inicio">
                      <div className="">
                        ventana 1
                      </div>
                      <div className="">
                          ventana 2
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>

            </Modal>

            <Modal id='BrandingModal' visible={this.state.BrandingModal} fade onClickBackdrop={ () => this.setState({ BrandingModal: false })} dialogClassName={ 'modal-dialog modal-dialog-centered' } >
              
              <div className='text-white py-3 px-5 rounded-top position-relative' style={{ backgroundColor: '#25373E', fontSize: '1.2em' }} >
                <FontAwesomeIcon onClick={ () => this.setState({ BrandingModal: false }) } style={{ position: 'absolute', width: '25px', right: '2%', color: 'white', cursor: 'pointer' }} icon={faTimes} />
                Diseño y <br/><b>Desarrollo Web</b>
              </div>
              <div className='d-flex' >
                <div className='w-50 py-1' style={{ backgroundColor: '#26B5B9' }} ></div>
                <div className='w-50 py-1' style={{ backgroundColor: '#FF9E00' }} ></div>
              </div>
              <div className='bg-white px-5 py-3 rounded-bottom' style={{ fontSize: '.85em' }} >
                <div className='' >
                  <div className='row' >
                    <div className='col-4 d-flex justify-content-center align-items-center' >
                      <img className='img-fluid' style={{ maxHeight: '100px' }} src={BrandingExpand} alt='p-lao' />
                    </div>
                    <div className='col-8' >
                      <p>Es la columna vertebral o los cimientos del desarrollo de toda publicación publicitaria de la marca. En él se encuentra la regulación del uso de la marca.</p>
                      <p>Este manual es indispensable en cualquier empresa para el desarrollo adecuado de su publicidad e imagen.</p>
                    </div>
                  </div>
                  <ul>
                    <li>Introducción</li>
                    <li>Índice
                      <ul>
                        <li>La navegabilidad</li>
                        <li>Interactividad</li>
                        <li>Usabilidad</li>
                        <li>Arquitectura de la información</li>
                      </ul>
                    </li>
                    <li>Red de construcción</li>
                    <li>Área de aislamiento</li>
                    <li>Tipografía Institucional</li>
                    <li>TIpografía Alternativa</li>
                    <li>Restricciones Tipográficas</li>
                    <li>Sistema cromático (Pantone, CMYK y RGB)</li>
                    <li>Restricciones en el manejo del color</li>
                    <li>Tamaños permitidos (Tabla proxémica)</li>
                    <li>Papelería Básica</li>
                    <li>Tarjeta de Presentación Corporativa</li>
                    <li>Hoja Carta Primera</li>
                    <li>Sobre Tamaño Carta</li>
                  </ul>
                  <div>
                    <p className='font-weight-bold' style={{ fontSize: '1.2em' }} >ALGUNOS TRABAJOS</p>
                    <Slider {...settings} className="galeria-inicio">
                      <div className="">
                        ventana 1
                      </div>
                      <div className="">
                          ventana 2
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>

            </Modal>

            <Modal id='EditorialModal' visible={this.state.EditorialModal} fade onClickBackdrop={ () => this.setState({ EditorialModal: false })} dialogClassName={ 'modal-dialog modal-dialog-centered' } >
              
              <div className='text-white py-3 px-5 rounded-top position-relative' style={{ backgroundColor: '#25373E', fontSize: '1.2em' }} >
                <FontAwesomeIcon onClick={ () => this.setState({ EditorialModal: false }) } style={{ position: 'absolute', width: '25px', right: '2%', color: 'white', cursor: 'pointer' }} icon={faTimes} />
                <b>DISEÑO EDITORIAL</b>
              </div>
              <div className='d-flex' >
                <div className='w-50 py-1' style={{ backgroundColor: '#26B5B9' }} ></div>
                <div className='w-50 py-1' style={{ backgroundColor: '#FF9E00' }} ></div>
              </div>
              <div className='bg-white px-5 py-3 rounded-bottom' style={{ fontSize: '1em' }} >
                <div className='' >
                  <div className='row' >
                    <div className='col-4 d-flex justify-content-center align-items-center' >
                      <img className='img-fluid' style={{ maxHeight: '100px' }} src={EditorialExpand} alt='p-lao' />
                    </div>
                    <div className='col-8' >
                      <p>Es una rama del diseño gráfico que se dedica a la diagramación y producción de diferentes piezas, como libros, revistas, periódicos, catálogos, etc.</p>
                    </div>
                  </div>
                  <br />
                  <ul>
                    <li>Catálogo o revista</li>
                    <li>Diseño de folleto</li>
                    <li>Manual normativo o instructivo</li>
                    <li>Diseño de troquelado y empaques</li>
                  </ul>
                  <div>
                    <p className='font-weight-bold' style={{ fontSize: '1.2em' }} >ALGUNOS TRABAJOS</p>
                    <Slider {...settings} className="galeria-inicio">
                      <div className="">
                        ventana 1
                      </div>
                      <div className="">
                          ventana 2
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>

            </Modal>

          </div>
          
        </div>

      </section>
        
      )
  }
} 

export default Servicios
